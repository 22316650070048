import { defineStore } from "pinia";
import { doc, updateDoc, query, where, getDocs, collection, arrayRemove, arrayUnion } from "firebase/firestore"; 
import { db, shiftsListener, userShiftsListener, updateDocOperation, createOperation, deleteOperation, fbGetShift, fbGetShiftUser, sendPushNotificationToUser } from "./firebase";
import { useAuthStore } from "@/store";
import { storeToRefs } from "pinia";


export const useShiftStore = defineStore("shiftStore", {
  // convert to a function
  state: () => ({
    shifts: null,
    allUserShifts: null,
    userShifts: null,
    currentShift: null,
    currentShiftID: null,
    error: null,
  }),
  getters: {
    allShifts: (state) => {
        if ( state.shifts !== null && state.userShifts !== null) {
          const authStore = useAuthStore()
          const { users } = storeToRefs(authStore)

        state.shifts.forEach((shift) => {
          //console.log(`Shift name: ${shift.name}`);
          //console.log(`Shift visibility: ${shift.visibility}`);
          const requestedShifts = []
          const acceptedShifts = []
          const declinedShifts = []
          const visibleShifts = []

          // REQUESTED SHIFTS
          state.userShifts
          .filter((userShift) => userShift.requestedShifts.includes(shift.id))
          .forEach((userShift) => {
            if (!userShift.declinedShifts.includes(shift.id)) {
              const user = users.value.find((u) => u.uid === userShift.userId);
              requestedShifts.push(user)
              //console.log(`Requested By - ${user.first_name} ${user.surname}`);
            }
          });

          // ACCEPTED SHIFTS
          state.userShifts
          .filter((userShift) => userShift.acceptedShifts.includes(shift.id))
          .forEach((userShift) => {
            const user = users.value.find((u) => u.uid === userShift.userId);
            acceptedShifts.push(user)
            //console.log(`Accepted By - ${user.first_name} ${user.surname}`);
          });

          // SENT BY ADMIN SHIFTS
          state.userShifts
          .filter((userShift) => userShift.visibleShifts.includes(shift.id))
          .forEach((userShift) => {
            const user = users.value.find((u) => u.uid === userShift.userId);
            visibleShifts.push(user)
            //console.log(`Sent To - ${user.first_name} ${user.surname}`);
          });

          // DECLINED SHIFTS
          state.userShifts
          .filter((userShift) => userShift.declinedShifts.includes(shift.id))
          .forEach((userShift) => {
            const user = users.value.find((u) => u.uid === userShift.userId);
            declinedShifts.push(user)
            //console.log(`Declined By - ${user.first_name} ${user.surname}`);
          });

          const unavailbableUsers = requestedShifts.concat(visibleShifts, acceptedShifts, declinedShifts);

          const availableUsers = users.value.filter(v => {
            //return (unavailbableUsers.indexOf(v.id) !== -1) ? false : true;
            return (unavailbableUsers.some(u => u.uid === v.uid)) ? false : true;
          });

          // SHIFT STAFF
          shift.staff = {
            requested: requestedShifts,
            admin_requested: visibleShifts,
            accepted: acceptedShifts,
            declined: declinedShifts,
            available: availableUsers,
            unavailbable: unavailbableUsers,
          }
          //console.log('--------------------------');
        });

        return state.shifts.slice().sort((a, b) => b.date_created.toDate() - a.date_created.toDate()) || []

      } else {
        return [];
      }
    },
    allPublicShifts: (state) => {
      if(state.shifts !== null) {
        return state.shifts.filter(shift => shift.visibility === 'public').slice().sort((a, b) => b.date_created.toDate() - a.date_created.toDate()) || [];
      }
    },
    getShift: (state) => { return state.currentShift},
    allShiftsCount() { return this.allShifts.length },
    shiftError: (state) => state.error, 
  },
  actions: {
    async getUserShiftsForShiftId(shiftId) {
    
      try {
          const userShifts = {
            visibleShifts: [],
            acceptedShifts: [],
            requestedShifts: [],
          };
          
          const visibleShiftsQuery = query(collection(db, 'user_shifts'), where('visibleShifts', 'array-contains', shiftId));
          const acceptedShiftsQuery = query(collection(db, 'user_shifts'), where('acceptedShifts', 'array-contains', shiftId));
          const requestedShiftsQuery = query(collection(db, 'user_shifts'), where('requestedShifts', 'array-contains', shiftId));
        
          const [visibleShiftsSnapshot, acceptedShiftsSnapshot, requestedShiftsSnapshot] = await Promise.all([
            getDocs(visibleShiftsQuery),
            getDocs(acceptedShiftsQuery),
            getDocs(requestedShiftsQuery),
          ]);
        
          visibleShiftsSnapshot.forEach((doc) => {
            const userShift = doc.data();
            userShifts.visibleShifts.push(userShift);
          });
        
          acceptedShiftsSnapshot.forEach((doc) => {
            const userShift = doc.data();
            userShifts.acceptedShifts.push(userShift);
          });
        
          requestedShiftsSnapshot.forEach((doc) => {
            const userShift = doc.data();
            userShifts.requestedShifts.push(userShift);
          });

        return userShifts;
      } catch(error) {
        console.log(error)
      }
    },
/*     allUserShifts() {
      return new Promise((resolve) => {
        allUserShiftsListener(async (data) => {
          const shifts = []
          if (data) {
            data.forEach((doc) => {
              shifts.push(doc)
            })
            this.allUserShifts = shifts;
            this.error = null;
            resolve(true);
          } 
        })
      })
    }, */
    getAllShifts() {
      return new Promise((resolve) => {
        shiftsListener(async (data) => {
          const shifts = []
          if (data) {
            data.forEach((doc) => {
              shifts.push(doc)
            })
            this.shifts = shifts;
            this.error = null;
            resolve(true);
          } 
        })
      })
    },
    getAllUserShifts() {
      return new Promise((resolve) => {
        userShiftsListener(async (data) => {
          const shifts = []
          if (data) {
            data.forEach((doc) => {
              shifts.push(doc)
            })
            this.userShifts = shifts;
            this.error = null;
            resolve(true);
          } 
        })
      })
    },
    setShiftID(value) {
      return this.currentShiftID = value
    },
    setShift(value) {
      return this.currentShift = value
    },
    async getShiftByID(shift_id) {
      const shift = await fbGetShiftUser(shift_id)
          if (shift) {
            return shift
          } else {
            return null;
          }
    },
    removeShift() {
      return this.currentShift.value = []
    },
    async acceptShiftUser(shift_id, user_id) {
      try {
          ////console.log('Requesting a shift with id of ', shift_id);
          const userShiftRef = doc(db, "user_shifts", user_id);
          await updateDoc(userShiftRef, {
            requestedShifts: arrayRemove(shift_id),
            acceptedShifts: arrayUnion(shift_id),
            visibleShifts: arrayRemove(shift_id),
          });
          const shift = await fbGetShift(shift_id)
          if (shift) {
            await sendPushNotificationToUser(
              user_id, 
              'Congratulations 🎉🎉🎉🎉🎉', 
              `You have been accepted for shift "${shift.name}". Visit the app for all shift information`,
              'shifts'
              )
          }
          this.error = null;
          return true;
      } catch (e) {
          //console.log(e);
          this.error = e;
      }
    },
    async declineShiftUser(shift_id, user_id) {
      try {
        const userRef = doc(db, "user_shifts", user_id);
        await updateDoc(userRef, {
          requestedShifts: arrayRemove(shift_id),
          declinedShifts: arrayUnion(shift_id),
        });
        const shift = await fbGetShift(shift_id)
        if (shift) {
          await sendPushNotificationToUser(
            user_id, 
            'About Your Recent Shift Request...', 
            `Unfortunately, you have not been accepted for shift "${shift.name}" at this time. Visit the app to see more available shifts.`,
            'shifts'
            )
        }
        this.error = null;
        return true;
      } catch (e) {
          console.log(e);
          this.error = e;
      }
    },
    async deleteShiftUser(shift_id, user_id) {
      try {
        const userRef = doc(db, "user_shifts", user_id);
        await updateDoc(userRef, {
          requestedShifts: arrayRemove(shift_id),
          declinedShifts: arrayRemove(shift_id),
          visibleShifts: arrayRemove(shift_id),
          acceptedShifts: arrayRemove(shift_id),
        });
        this.error = null;
        return true;
      } catch (err) {
        this.error = err
      } 
    },
    async requestShiftUser(user_id, shift_id) {
      try {
        const userRef = doc(db, "user_shifts", user_id);
        await updateDoc(userRef, {
          visibleShifts: arrayUnion(shift_id),
        });
        const shift = await fbGetShift(shift_id)
        if (shift) {
          await sendPushNotificationToUser(
            user_id, 
            'New Shift Request', 
            `New request for shift "${shift.name}". Are you available? Vist the app to accept 👍 or decline 👎`,
            'shifts'
            )
        }
        this.error = null;
        return true;
      } catch (err) {
        this.error = err
      } 
    },
    async addShift(shift) {
      try {
          ////console.log('Cancelling a shift request with id of ', shift_id);
          await createOperation('shifts', shift);
          this.error = null;
          return true;
      } catch (e) {
          //console.log(e);
          this.error = e;
      }
    },
    async adminAddShift(shift) {
      try {
          ////console.log('Cancelling a shift request with id of ', shift_id);
          const newShift = await createOperation('shifts', shift);
          this.error = null;
          return newShift;
      } catch (e) {
          //console.log(e);
          this.error = e;
      }
    },
    async deleteShift(shift_id) {
      try {
          ////console.log('Cancelling a shift request with id of ', shift_id);
          await deleteOperation('shifts', shift_id);
          this.error = null;
          return true;
      } catch (e) {
          //console.log(e);
          this.error = e;
      }
    },
    async updateShift(shift_id, shift) {
      try {
        delete shift.shiftUsers;
        delete shift.staff;
        await updateDocOperation('shifts', shift_id, shift);
        this.error = null;
        return true;
      } catch (e) {
        this.error = e;
      }
    },
  },
  //persist: true,
});
