import { defineStore } from "pinia";
import { 
  fbCollectionListener, 
  queryObjectCollection, 
  createOperation, 
  updateDocOperation, 
  deleteOperation,
  batchDeleteOperation,
  sendPushNotificationToUser } from "./firebase";
import { useAuthStore } from "@/store";
import { format } from "date-fns";

export const useTimesheetStore = defineStore("timesheetStore", {
  // convert to a function
  state: () => ({
    newTimesheetDates: [],
    newSingleDatesFormatted: [],
    new_timesheet: {},
    timesheets: [],
    accepted_timesheets: [],
    rejected_timesheets: [],
    pending_timesheets: [],
    error: null,
  }),
  getters: {
    timesheetUsers: (state) => {
      if (!state.timesheets || !state.timesheets.length) return;
      
      const { users } = useAuthStore();
      
      if (!users || !users.length) return;
      
      state.timesheets.forEach((sheet) =>  {
        // eslint-disable-next-line no-unused-vars
        const arrUsers = Object.entries(users).map(([key, value]) => value.uid )

        sheet.user = users.filter((el) => {
          return state.timesheets.some(() => {
            return sheet.uid === el.uid;
          });
        })
        
      }) 

      return state.timesheets.slice().sort((a, b) => b.date_created.toDate() - a.date_created.toDate())
    },
    pendingTimesheets: (state) => state.timesheets.filter((sheet) => {
      return sheet.status == 1
    }),
    pendingTimesheetsCount() { return this.pendingTimesheets.length },
    acceptedTimesheets: (state) => state.timesheets.filter((sheet) => {
      return sheet.status == 2
    }),
    acceptedTimesheetsCount() { return this.acceptedTimesheets.length },
    rejectedTimesheets: (state) => state.timesheets.filter((sheet) => {
      return sheet.status == 3
    }),
    rejectedTimesheetsCount() { return this.rejectedTimesheets.length },
    timesheetError: (state) => state.error,
  },
  actions: {
      /**
       * listen for changes on collection and update
       * store.
       * 
       * @param collectionName 
       * @returns 
       */
    initializeCollectionListener() {
      return new Promise((resolve) => {
        fbCollectionListener('timesheets', async (data) => {
          this.timesheets = data ? data : null;
          this.error = null;
          resolve(true);
        });
      });
    },
    /**
     * make intentional call to load users timesheets for collectsion
     * 
     * @param data
     */
    async loadTimesheets() {
      try {
        const data = await queryObjectCollection({
          collectionName: "timesheets",
        });
        this.timesheets = data ? data : null;
        this.error = null;
        return this.timesheets;
      } catch (e) {
        this.timesheets = null;
        this.error = e;
        return false;
      }
    },
    async addDateToTimesheet(date) {
      this.newTimesheetDates.push({...date})
    },
    async removeDateFromTimesheet(index) {
      this.newTimesheetDates.splice(index, 1);
      this.newSingleDatesFormatted.splice(index, 1);
    },
    // SAVE NEW TIMESHEET
    async saveNewTimesheet(timesheet) {

        try {
            //console.log('Data', timesheet);
            await createOperation('timesheets', timesheet);
            this.new_timesheet = timesheet;
            this.newSingleDatesFormatted = []
            this.newTimesheetDates = [];
            this.error = null;
            return true;
        } catch (e) {
            this.new_timesheet = null;
            this.error = e;
        }
    },
    formatDateEN(date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },
    sheetStaus(status) {
      if(status == 1) {
        return 'pending'
      } else if (status === 2) {
        return 'accepted 🙌'
      } else if (status === 3) {
        return 'rejected ⚠️'
      }
    },
    // UPDATE TIMESHEER
    async updateTimesheet(timesheet_id, sheet) {
      try {
          //console.log('Update Data', timesheet_id, sheet);
          await updateDocOperation('timesheets', timesheet_id, sheet);
            if (sheet.status !== 1) {
            await sendPushNotificationToUser(
              sheet.uid, 
              'Timesheet Status Update', 
              `Your timesheet for week starting "${this.formatDateEN(sheet.week_start_date)}" has been ${this.sheetStaus(sheet.status)}.`,
              'timesheets'
              )
            }
          
          //this.new_timesheet = timesheet;
          this.newSingleDatesFormatted = []
          //this.newTimesheetDates = [];
          this.error = null;
          return true;
      } catch (e) {
          this.new_timesheet = null;
          this.error = e;
      }
  },
  async deleteTimesheet(timesheet) {

      try {
          //console.log('Data', timesheet);
          await deleteOperation('timesheets', timesheet);
          //this.new_timesheet = timesheet;
          //this.newSingleDatesFormatted = []
          //this.newTimesheetDates = [];
          this.error = null;
          return true;
      } catch (e) {
          //this.new_timesheet = null;
          this.error = e;
      }
  },
  async batchDeleteTimesheets(timesheets) {

    try {
        //console.log('Data', timesheet);
        await batchDeleteOperation('timesheets', timesheets);
        //this.new_timesheet = timesheet;
        //this.newSingleDatesFormatted = []
        //this.newTimesheetDates = [];
        this.error = null;
        return true;
    } catch (e) {
        //this.new_timesheet = null;
        this.error = e;
    }
},
  },
});