import { defineStore } from "pinia";
import { createOperationByCollectionID, queryObjectCollection } from "./firebase";

export const useCalendarStore = defineStore("calendarStore", {
  // convert to a function
  state: () => ({
    calendars: [],
    calendars_error: null,
  }),
  getters: {
    allCalendars: (state) => state.calendars,
    calendarError: (state) => state.calendars_error,
  },
  actions: {
      /**
       * listen for changes on collection and update
       * store.
       * 
       * @param collectionName 
       * @returns 
       */
    // SAVE NEW TIMESHEET
    async saveUserDates(id, dates) {

        try {
            //console.log('Dates before converting', dates);
            const newD = dates?.map((d) => { return new Date(d) })
            const dataObj = {
                dates: newD
            }
            console.log('Dates obj after conversion and before save', dataObj);
            const dateData = await createOperationByCollectionID('calendars', id, dataObj);
            console.log('awaited data that was saved: ', dateData)
            //this.calendars = dataObj;
            this.calendars_error = null;
            return true;
        } catch (e) {
            //this.new_date = null;
            this.calendars_error = e;
        }
    },
    /**
     * make intentional call to load calendars for collectsion
     * 
     * @param data
     */
    async loadCalendars() {
      try {
        const data = await queryObjectCollection({
          collectionName: "calendars",
        });
        this.calendars = data ? data : null;
        this.calendars_error = null;
        return this.calendars;
      } catch (e) {
        this.calendars = null;
        this.calendars_error = e;
        return false;
      }
    },
  },
});