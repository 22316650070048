import { useAuthStore } from "@/store";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import TabsPage from "../views/TabsPage.vue";

/**
 * 
 * @param to 
 * @param from 
 * @param next 
 */
const authCheck = (to, from, next) => {
  const store = useAuthStore();
  if (store.isLoggedIn && store.isAdmin) {
    if (to.name === "login") {
      next({ name: "staff" });
    } else {
      next();
    }
  } else {
    //console.log('is not logged in')
    if (to.name === "login") {
      next();
    } else {
      next({ name: "login" });
    }
  }
};

const routes = [
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("@/views/ResetPasswordPage.vue"),
  },
  {
    path: "/",
    redirect: "/tabs/staff",
  },
  {
    path: "/tabs/",
    component: TabsPage,
    children: [
      {
        path: "",
        redirect: "staff",
      },
      {
        path: "shifts",
        name: "shifts",
        component: () => import("@/views/ShiftsPage.vue"),
        beforeEnter: authCheck,
      },
      {
        path: "chat",
        name: "chat",
        component: () => import("@/views/ChatPage.vue"),
        beforeEnter: authCheck,
      },
      {
        path: "staff",
        name: "staff",
        component: () => import("@/views/StaffPage.vue"),
        beforeEnter: authCheck,
      },
      {
        path: "timesheets",
        name: 'timesheets',
        component: () => import("@/views/TimesheetsPage.vue"),
        beforeEnter: authCheck,
      },
      {
        path: "message",
        name: "message",
        component: () => import("@/views/MessagePage.vue"),
        beforeEnter: authCheck,
      },
      {
        path: "bulletins",
        name: "bulletins",
        component: () => import("@/views/BulletinsPage.vue"),
        beforeEnter: authCheck,
      },
      {
        path: "requests",
        name: "requests",
        component: () => import("@/views/RequestsPage.vue"),
        beforeEnter: authCheck,
      },
      {
        path: "employers",
        name: "employers",
        component: () => import("@/views/EmployersPage.vue"),
        beforeEnter: authCheck,
      },
/*       {
        path: "calendar",
        name: "calendar",
        component: () => import("@/views/CalendarPage.vue"),
        beforeEnter: authCheck,
      }, 
      */
    ],
  },
  // { path: "*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
