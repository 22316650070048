import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.scss";

import { createPinia } from "pinia";

import { useAuthStore } from "./store";
const pinia = createPinia();

import VueTrumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64.min.js';
import 'trumbowyg/dist/ui/trumbowyg.css';
import './registerServiceWorker'

const app = createApp(App).use(IonicVue, {mode: 'md'}).use(pinia).use(VueTrumbowyg)

// get the store
const store = useAuthStore();

// initialize auth listener to see if we
// have a user at startup
store.initializeAuthListener().then(() => {
  app.use(router).mount("#app");
});
