import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import {
  getFirestore,
  getDoc,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  getDocs,
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  updateDoc,
  deleteField,
  writeBatch
} from "firebase/firestore";

import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  updateEmail,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from "firebase/auth";

import axios from 'axios';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const fbStorage = getStorage(app);

export const sendPushNotificationToUser = async (userId, title, message, path) => {

  const user = await fbGetUser(userId)
  if (user && user.fcmToken) {
    const options = {
      method: 'POST',
      url: 'https://onesignal.com/api/v1/notifications',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Basic ${process.env.VUE_APP_ONESIGNAL_API_KEY}`,
        'Content-Type': 'application/json'
      },
      data: {
        app_id: process.env.VUE_APP_ONESIGNAL_APP_ID,
        include_player_ids: [user.fcmToken],
        contents: {en: message},
        headings: {en: title},
        small_icon: '@mipmap/ic_stat_ic_notification',
        badge_count: '++',
        ios_badgeType: 'Increase',
        ios_badgeCount: 1,
        ios_attachments: {
          id1: '../assets/icon.png'
        },
        data: {
          'path': path
        }
      }
    };
    
    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }
};

export const sendPushNotificationToAll = async (title, message, path) => {

    const options = {
      method: 'POST',
      url: 'https://onesignal.com/api/v1/notifications',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Basic ${process.env.VUE_APP_ONESIGNAL_API_KEY}`,
        'Content-Type': 'application/json'
      },
      data: {
        app_id: process.env.VUE_APP_ONESIGNAL_APP_ID,
        "included_segments": ["All"],
        contents: {en: message},
        headings: {en: title},
        small_icon: '@mipmap/ic_stat_ic_notification',
        badge_count: '++',
        ios_badgeType: 'Increase',
        ios_badgeCount: 1,
        ios_attachments: {
          id1: '../assets/icon.png'
        },
        data: {
          'path': path
        }
      }
    };
    
    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
};

export const fbUpdateAccount = async (
  first_name,
  surname,
  date_of_birth,
  cscs_no,
  national_insurance_no,
  qualification,
  address_line_1,
  address_line_2,
  city,
  postcode,
  tel,
) => {
  await fbUpdateUser(
    first_name,
    surname,
    date_of_birth,
    cscs_no,
    national_insurance_no,
    qualification,
    address_line_1,
    address_line_2,
    city,
    postcode,
    tel,
  );

  const profile = await fbGetUserProfile()

  return {
    profile: profile ? profile : null
  }
}

export const fbGetUser = async (uid) => {
  const usersCollection = collection(db, "users");
  const querySnapshot = await getDocs(query(usersCollection, where("uid", "==", uid)));

  // Check if the query returned any documents
  if (!querySnapshot.empty) {
    // The query returned at least one document, so extract the first document
    const userDoc = querySnapshot.docs[0];
    
    // Get the data for the user document
    const userData = userDoc.data();
    
    // Do something with the user data
    console.log(userData);

    return userData
  } else {
    // The query did not return any documents
    console.log("No user found with uid:", uid);
    return false
  }
}

export const fbGetShift = async (id) => {

  // Get a reference to the shift document by its ID
  const shiftRef = doc(db, 'shifts', id);

  // Retrieve the document snapshot
  const shiftSnapshot = await getDoc(shiftRef);

  // Check if the document exists
  if (shiftSnapshot.exists()) {
    // Access the data of the shift document
    const shiftData = shiftSnapshot.data();
    console.log('Shift data:', shiftData);
    return shiftData
  } else {
    console.log('No shift with document id of ', id);
    return false
  }
}

function compareDates(date1, date2) {
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  if (year1 > year2) {
    return true;
  } else if (year1 === year2 && month1 > month2) {
    return true;
  } else if (year1 === year2 && month1 === month2 && day1 >= day2) {
    return true;
  } else {
    return false;
  }
}

export const fbGetShiftUser = async (id) => {
  // Get a reference to the shift document by its ID
  const shiftRef = doc(db, 'user_shifts', id);

  // Retrieve the document snapshot
  const shiftSnapshot = await getDoc(shiftRef);

  // Check if the document exists
  if (shiftSnapshot.exists()) {
    // Access the data of the shift document
    const shiftData = shiftSnapshot.data();

    // Get a reference to the user document by user ID
    const userRef = doc(db, 'users', shiftData.userId);

    // Retrieve the user document snapshot
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      // Access the data of the user document
      const userData = userSnapshot.data();

      // Extract shift IDs from different arrays
      const shiftIds = [
        ...shiftData.requestedShifts.map(id => ({ id, type: 'requested' })),
        ...shiftData.visibleShifts.map(id => ({ id, type: 'visible' })),
        ...shiftData.acceptedShifts.map(id => ({ id, type: 'accepted' })),
        ...shiftData.declinedShifts.map(id => ({ id, type: 'declined' }))
      ];

      const now = new Date();
      const upcomingShifts = [];
      const historicShifts = [];

      for (const shiftIdObj of shiftIds) {
        const shift = await fbGetShiftById(shiftIdObj.id);
    
        if (shift) {
          const shiftWithType = {
            ...shift,
            id: shiftIdObj.id,
            status: shiftIdObj.type // Add the type property to the shift
          };
    
          const shiftStartDate = shift.start_date.toDate(); // Convert Firebase Timestamp to Date

/*           console.log('Shift Start Date:', shiftStartDate.toDateString());
          console.log('Current Date:', now.toDateString());
          console.log('Comparison Result:', shiftStartDate.toDateString() >= now.toDateString());
 */
    
          // Compare only the dates without the time component
          if (compareDates(shiftStartDate, now)) {
            upcomingShifts.push(shiftWithType);
          } else {
            historicShifts.push(shiftWithType);
          }
        }
      }

      // Merge user and shift data
      const resultData = {
        ...shiftData,
        ...userData,
        upcomingShifts,
        historicShifts
      };

      //console.log('Shift data:', resultData);
      return resultData;
    } else {
      console.log('No user with ID:', shiftData.userId);
      return false;
    }
  } else {
    console.log('No shift with document ID:', id);
    return false;
  }
};

async function fbGetShiftById(shiftId) {
  const shiftRef = doc(db, 'shifts', shiftId);
  const shiftSnapshot = await getDoc(shiftRef);
  return shiftSnapshot.exists() ? shiftSnapshot.data() : null;
}

export const fbUpdateUser = async (
  first_name,
  surname,
  date_of_birth,
  cscs_no,
  national_insurance_no,
  qualification,
  address_line_1,
  address_line_2,
  city,
  postcode,
  tel,
) => {
  const user = auth.currentUser;
  const ref = doc(db, "users", user.uid);
  await setDoc(
    ref,
    {
      first_name: first_name,
      surname: surname,
      date_of_birth: date_of_birth,
      cscs_no: cscs_no,
      national_insurance_no: national_insurance_no,
      qualification: qualification,
      address_line_1: address_line_1,
      address_line_2: address_line_2,
      city: city,
      postcode: postcode,
      tel: tel,
    },
    { merge: true }
  );
  return true;
};

export const fbUpdateUserFiles = async (
  fileName, filePath
) => {
  const user = auth.currentUser;
  const ref = doc(db, "users", user.uid);
  let documents = {}
  documents[fileName] = filePath
  await setDoc(
    ref,
    {
      documents
    },
    { merge: true }
  );
  return true;
};

export const fbDeleteUserFilePath = async (
  fileName
) => {
  const user = auth.currentUser;
  const ref = doc(db, "users", user.uid);
  try {
    await updateDoc(ref, 
      `documents.${fileName}`, deleteField()
    )
    return true
  } catch (error) {
    //console.log(error);
    return false
  }

}

export const fbUpdateUserPassword = async (
auth, email
) => {
await sendPasswordResetEmail(auth, email)
  .then(() => {
    //console.log('password reset email sent')
  })
  .catch((error) => {
    return error.code;
  });
}

export const fbUpdateUserEmail = async (
  auth, newEmail
  ) => {
    const docRef = doc(db, "users", auth.uid);
    const data = {
      email: newEmail
    }

     await updateEmail(auth, newEmail).then( async () => {
      await setDoc(docRef, data, { merge:true }).then(async () => {
        //console.log('email address updated')
      })
      return true;
    })
    .catch((error) => {
      //const errorCode = error.code;
      const errorMessage = error.message;
      return errorMessage
    }); 
}

// get user profile
export const fbGetUserProfile = async () => {
  const user = auth.currentUser;
  const ref = doc(db, "users", user?.uid);
  const docSnap = await getDoc(ref);

  if (docSnap.exists()) {
    //console.log("user profile data:", docSnap.data());
    return {
      ...docSnap.data(),
    };
  } else {
    // doc.data() will be undefined in this case
    //console.log("No User Profile Found", user?.uid);
    return null;
  }
};

// get user calendar
export const fbGetUserCalendar = async () => {
  const user = auth.currentUser;
  //console.log(user);
  const ref = doc(db, "calendars", user?.uid);
  const docSnap = await getDoc(ref);

  if (docSnap.exists()) {
    //console.log("user calendar data:", docSnap.data().dates);
    return {
      ...docSnap.data().dates,
    };
  } else {
    // doc.data() will be undefined in this case
    //console.log("No User Calendar Found", user?.uid);
    return null;
  }
};

/**
 *
 * @param email
 * @param password
 * @returns
 */
export const fbSignIn = async (email, password) => {
  const response = await signInWithEmailAndPassword(auth, email, password);
  //console.log(response);
  return response;
};

/**
 *
 * @returns
 */
export const fbSignOut = async () => {
  unsubscribeToListener('users')
  unsubscribeToListener('calendars')
  unsubscribeToListener('timesheets')
  unsubscribeToListener('shifts')
  unsubscribeToListener('safety_bulletins')
  unsubscribeToListener('user_requests')
  await signOut(auth);
  return true;
};


// BULLETINS
export const fbUpdateBulletinFiles = async (
  bulletinID, fileName, filePath
) => {
  const ref = doc(db, "safety_bulletins", bulletinID);
  let documents = {}
  documents[fileName] = filePath
  await setDoc(
    ref,
    {
      documents
    },
    { merge: true }
  );
  return true;
};

/**
 *
 * @param callback
 */
export const fbAuthStateListener = (callback) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      callback(user);
    } else {
      // User is signed out
      callback(null);
    }
  });
};

export const unsubscribeToListener = (collectionName) => {
  //getDocs(collection(db, collectionName))
  /* const ref = collection(db, collectionName);
  onSnapshot(collection(db, collectionName)
  const unsub = onSnapshot(doc(db, "cities", "SF"), (doc) => {
  });

  return unsub() */
  console.log(collectionName)
};

/**
 *
 * EMPLOYEE
 */
export const fbUpdateEmployee = async (
  uid,
  first_name,
  surname,
  date_of_birth,
  cscs_no,
  national_insurance_no,
  qualification,
  address_line_1,
  address_line_2,
  city,
  postcode,
  tel,
  emergency_contact_name,
  emergency_contact_tel
) => {
  const ref = doc(db, "users", uid);
  await setDoc(
    ref,
    {
      first_name: first_name,
      surname: surname,
      date_of_birth: date_of_birth,
      cscs_no: cscs_no,
      national_insurance_no: national_insurance_no,
      qualification,
      address_line_1: address_line_1,
      address_line_2: address_line_2,
      city: city,
      postcode: postcode,
      tel: tel,
      emergency_contact_name: emergency_contact_name,
      emergency_contact_tel: emergency_contact_tel
    },
    { merge: true }
  );
  return true;
};

/**
 *
 * @param {*} param0
 */
export const queryObjectCollection = async ({
  collectionName,
}) => {
  const querySnapshot = await getDocs(collection(db, collectionName));
  const results = [];

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    results.push({
      ...doc.data(),
      id: doc.id,
    });
  });
  return results;
};

export const queryUserDocumentsInCollection = async ({
  collectionName, user_id
}) => {
  // eslint-disable-next-line no-undef
  const q = query(collection(db, collectionName), where("uid", "==", user_id));
  const results = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    //console.log(doc.id, " => ", doc.data());
    results.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return results;
}

export const createOperation = async (collectionName, data) => {
  const docRef = await addDoc(collection(db, collectionName), data);
  //console.log(`Document for '${collectionName}' written with ID: `, docRef.id);
  return docRef;
}

export const setDocOperation = async (collectionName, data) => {
  const docRef = await setDoc(collection(db, collectionName), data);
  //console.log(`Document for '${collectionName}' written with ID: `, docRef.id);
  return docRef; 
}

export const updateDocOperation = async (collectionName, docId, data) => {
  const ref = doc(db, collectionName, docId);
  await setDoc(ref, data, { merge:true }).then(async () => {
    //console.log(`Document ${docId} has been updated successfully`)
  })
  return true;
}

export const createOperationByCollectionID = async (collectionName, id, data) => {
  const docRef = await setDoc(doc(db, collectionName, id), data);
  //console.log(`Document for '${collectionName}' written with ID: `, id);
  return docRef; 
}

export const deleteOperation = async (collectionName, id) => {
  const docRef = await deleteDoc(doc(db, collectionName, id));
  //console.log(`Document from '${collectionName}' with ID of '${id}' has been deleted: `, docRef);
  return docRef;
}

export const batchDeleteOperation = async (collectionName, ids) => {
  const batch = writeBatch(db);

  ids.forEach(id => {
    const docRef = doc(db, collectionName, id);
    batch.delete(docRef);
  });

  try {
    await batch.commit();
    //console.log(`Documents from '${collectionName}' with IDs ${ids.join(', ')} have been deleted`);
    return true;
  } catch (error) {
    console.error('Error deleting documents:', error);
    return false;
  }
};

/**
 * 
 * @param collectionName 
 * @param callback 
 */
export const fbCollectionListener = (collectionName, callback) => {
  onSnapshot(
    collection(db, collectionName),
    (snapshot) => {
      const results = [];
      snapshot.forEach((doc) => {
        results.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      callback(results);
    },
    (error) => {
      console.log("Error Listening To Collection: " + collectionName, error);
    }
  );
};

export const fbCollectionListenerByDocID = (collectionName, docID, callback) => {
  onSnapshot(doc(db, collectionName, docID), (doc) => {
    //console.log(`Listening To ${collectionName} By Doc ID: ${docID}`);
    const currDateObj = {
      dates: null
    }
    const results = doc.data() ? doc.data() : currDateObj;
    callback(results);
  });
};

export const fbCollectionListenerByUserID = (collectionName, userID, callback) => {
  const q = query(collection(db, collectionName), where("uid", "==", userID));
  onSnapshot(q, (querySnapshot) => {
    const data = [];
    querySnapshot.forEach((doc) => {
        data.push({id: doc.id, ...doc.data()});
    });
    //console.log("Listening To Collection By User ID: " + collectionName, userID, data);
    //console.log(data)
    callback(data);
  })
}

/* export const shiftsListener = async (callback) => {

  const q = query(collection(db, 'shifts'))
  onSnapshot(q, async (querySnapshot) => {
    const shifts = [];
    querySnapshot.forEach((doc) => {
        shifts.push({
          id: doc.id,
          ...doc.data(),
          shiftUsers: []
        });
    })
    for (const shift of shifts) {
      const requestedUsersQuery = query(
        collection(db, 'user_shifts'),
        where('requestedShifts', 'array-contains', shift.id)
      );
      const requestedUsersSnapshot = await getDocs(requestedUsersQuery);
      const requestedUsers = [];
      for (const doc of requestedUsersSnapshot.docs) {
        const userShift = doc.data();
        const userDoc = await getDoc(doc.ref);
        const user = { id: userDoc.id, ...userDoc.data() };
        requestedUsers.push({ ...userShift, user });
      }
      shift.shiftUsers.push({ group: 'requested', users: requestedUsers });

      const acceptedUsersQuery = query(
        collection(db, 'user_shifts'),
        where('acceptedShifts', 'array-contains', shift.id)
      );
      const acceptedUsersSnapshot = await getDocs(acceptedUsersQuery);
      const acceptedUsers = [];
      for (const doc of acceptedUsersSnapshot.docs) {
        const userShift = doc.data();
        const userDoc = await getDoc(doc.ref);
        const user = { id: userDoc.id, ...userDoc.data() };
        acceptedUsers.push({ ...userShift, user });
      }
      shift.shiftUsers.push({ group: 'accepted', users: acceptedUsers });

      const visibleUsersQuery = query(
        collection(db, 'user_shifts'),
        where('visibleShifts', 'array-contains', shift.id)
      );
      const visibleUsersSnapshot = await getDocs(visibleUsersQuery);
      const visibleUsers = [];
      for (const doc of visibleUsersSnapshot.docs) {
        const userShift = doc.data();
        const userDoc = await getDoc(doc.ref);
        const user = { id: userDoc.id, ...userDoc.data() };
        visibleUsers.push({ ...userShift, user });
      }
      shift.shiftUsers.push({ group: 'visible', users: visibleUsers });
    }
    callback(shifts)
  },
  (error) => {
    console.log("Error on shifts: ", error);
  });
}; */

export const shiftsListener = async (callback) => {
  const q = query(collection(db, 'shifts'));
  
  onSnapshot(q, async (querySnapshot) => {
    const shifts = [];
    
    const userShiftsQuery = query(collection(db, 'user_shifts'));
    const userShiftsSnapshot = await getDocs(userShiftsQuery);
    const userShiftsMap = {};
    
    userShiftsSnapshot.forEach((doc) => {
      const userShift = doc.data();
      const shiftId = userShift.requestedShifts || userShift.acceptedShifts || userShift.visibleShifts;
      if (shiftId) {
        userShiftsMap[shiftId] = userShiftsMap[shiftId] || [];
        userShiftsMap[shiftId].push({ id: doc.id, ...userShift });
      }
    });
    
    querySnapshot.forEach((doc) => {
      const shiftData = doc.data();
      const shift = {
        id: doc.id,
        ...shiftData,
        shiftUsers: []
      };
      
      shift.shiftUsers.push({
        group: 'requested',
        users: userShiftsMap[shift.id]?.filter((userShift) => userShift.requestedShifts).map((userShift) => ({
          ...userShift,
          user: null
        })) || []
      });
      
      shift.shiftUsers.push({
        group: 'accepted',
        users: userShiftsMap[shift.id]?.filter((userShift) => userShift.acceptedShifts).map((userShift) => ({
          ...userShift,
          user: null
        })) || []
      });
      
      shift.shiftUsers.push({
        group: 'visible',
        users: userShiftsMap[shift.id]?.filter((userShift) => userShift.visibleShifts).map((userShift) => ({
          ...userShift,
          user: null
        })) || []
      });
      
      shifts.push(shift);
    });
    
    const userIds = new Set();
    Object.values(userShiftsMap).forEach((userShifts) => {
      userShifts.forEach((userShift) => {
        userIds.add(userShift.userId);
      });
    });
    
    const userIdsArray = Array.from(userIds);
    const batchSize = 10;
    const batches = [];
    for (let i = 0; i < userIdsArray.length; i += batchSize) {
      const batch = userIdsArray.slice(i, i + batchSize);
      batches.push(batch);
    }
    
    const usersMap = {};
    
    for (const batch of batches) {
      const usersQuery = query(collection(db, 'users'), where('id', 'in', batch));
      const usersSnapshot = await getDocs(usersQuery);
      
      usersSnapshot.forEach((doc) => {
        usersMap[doc.id] = { id: doc.id, ...doc.data() };
      });
    }
    
    shifts.forEach((shift) => {
      shift.shiftUsers.forEach((group) => {
        group.users.forEach((userShift) => {
          const user = usersMap[userShift.userId];
          if (user) {
            userShift.user = user;
          }
        });
      });
    });
    
    callback(shifts);
  },
  (error) => {
    console.log("Error on shifts: ", error);
  });
};

export const userShiftsListener = async (callback) => {

  const q = query(collection(db, 'user_shifts'));
  onSnapshot(q, (querySnapshot) => {
    const userShifts = [];
    querySnapshot.forEach((doc) => {
      const userShift = doc.data();
      userShifts.push(userShift);
    });
    callback(userShifts);
  },
  (error) => {
    console.log("Error on user_shifts: ", error);
  });
};

// Requested By Admin
export const visibleShiftsListener = async (user_id, callback) => {
  const userDocRef = doc(db, 'user_shifts', user_id);

  onSnapshot(userDocRef, (snapshotDoc) => {
    const visibleShifts = snapshotDoc.data().visibleShifts || [];

    const promises = visibleShifts.map(async (shift_id) => {
      const shiftDocRef = doc(db, 'shifts', shift_id);
      const shiftDoc = await getDoc(shiftDocRef);

      if (shiftDoc.exists()) {
        return {
          id: shiftDoc.id,
          ...shiftDoc.data(),
        };
      }
      return null;
    });

    Promise.all(promises).then((shifts) => {
      const filteredShifts = shifts.filter((shift) => shift !== null);
      callback(filteredShifts);
    });
  }, (error) => {
    console.log("Error on visible (admin requested) shifts: ", error);
  });
};

// Requested By User
export const requestedShiftsListener = (user_id, callback) => {
  const userDocRef = doc(db, 'user_shifts', user_id);

  onSnapshot(userDocRef, (snapshotDoc) => {
    const requestedShifts = snapshotDoc.data().requestedShifts || [];

    const promises = requestedShifts.map(async (shift_id) => {
      const shiftDocRef = doc(db, 'shifts', shift_id);
      const shiftDoc = await getDoc(shiftDocRef);

      if (shiftDoc.exists()) {
        return {
          id: shiftDoc.id,
          ...shiftDoc.data(),
        };
      }
      return null;
    });

    Promise.all(promises).then((shifts) => {
      const filteredShifts = shifts.filter((shift) => shift !== null);
      callback(filteredShifts);
    });
  }, (error) => {
    console.log("Error on user requested shifts: ", error);
  });
};

// Accepted Shifts
export const acceptedShiftsListener = (user_id, callback) => {
  const userDocRef = doc(db, 'user_shifts', user_id);

  onSnapshot(userDocRef, (snapshotDoc) => {
    const acceptedShifts = snapshotDoc.data().acceptedShifts || [];

    const promises = acceptedShifts.map(async (shift_id) => {
      const shiftDocRef = doc(db, 'shifts', shift_id);
      const shiftDoc = await getDoc(shiftDocRef);

      if (shiftDoc.exists()) {
        return {
          id: shiftDoc.id,
          ...shiftDoc.data(),
        };
      }
      return null;
    });

    Promise.all(promises).then((shifts) => {
      const filteredShifts = shifts.filter((shift) => shift !== null);
      callback(filteredShifts);
    });
  }, (error) => {
    console.log("Error on accepted shifts: ", error);
  });
};


export const sendMessageOperation = async (data) => {

  console.log('send not general msg to: ', data.to)

  for( var i = 0; i < data.to.length; i++ ) {
    await addDoc(collection(db, 'messages'), {
      to: data.to[i].tel,
      body: data.body
    }).then((success) => {
      console.log(`message sent to ${data.to[i].tel}`, success)
    }).catch((e) => console.log(e))
  }

}

export const sendGeneralMessageOperation = async (data) => {
  console.log('send general msg to: ', data.to)
  //console.log(data.to)

  for( var i = 0; i < data.to.length; i++ ) {

    await addDoc(collection(db, 'messages'), {
      to: data.to[i],
      body: data.body
    }).then((success) => {
      console.log(`message sent to ${data.to[i]}`, success)
    }).catch((e) => console.log(e))
  }

}


// CHATS

export const fbChatsCollection = (callback) => {  
    onSnapshot(
      query(
          collection(db, 'chats'),
          orderBy('date', 'desc')
      ),
      (snapshot) => {
          const results = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
          });
          callback(results);
      }
    )
};

export { app, db, auth, fbStorage };
