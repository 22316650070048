import { defineStore } from "pinia";
import { fbStorage } from "@/store/firebase";

import { ref as storageRef, listAll, uploadBytesResumable, uploadString, getDownloadURL, deleteObject } from "@firebase/storage"

import {
    fbUpdateUserFiles,
    fbDeleteUserFilePath
} from "./firebase";

function delay(t, v) {
    return new Promise(function(resolve) { 
      setTimeout(resolve.bind(null, v), t)
    });
}

function keepTrying(triesRemaining, sRef) {
    if (triesRemaining < 0) {
        return Promise.reject('out of tries');
    }
    
    return getDownloadURL(sRef).then((url) => {
        return url;
    }).catch((error) => {
        switch (error.code) {
        case 'storage/object-not-found':
            return delay(2000).then(() => {
            return keepTrying(triesRemaining - 1, sRef)
            });
        default:
            //console.log(error);
            return Promise.reject(error);
        }
    })
}

export const useUploadStore = defineStore("uploadStore", {
    // convert to a function
    state: () => ({
        files: null,
        filesError: null,
        error: null,
    }),
    getters: {
    },
    actions: {
        async listUserFiles(userID) {
            const listRef = storageRef(fbStorage, `users/${userID}/profile/`);
            this.files = [];
            listAll(listRef)
            .then((res) => {
                //console.log(res)
                //res.prefixes.forEach((folderRef) => {
                // All the prefixes under listRef.
                // You may call listAll() recursively on them.
                //console.log(folderRef)
                //});
                res.items.forEach((itemRef) => {
                // All the items under listRef.
                    //console.log(itemRef)
                    const url = itemRef.fullPath;//getDownloadURL(itemRef);
                    this.files.push({ name: itemRef.name, url });
                });
            }).catch((error) => {
                // Uh-oh, an error occurred!
                this.filesError = error
            });

        },
        /**
         *
         * @param upload user files
         */
        async uploadFile(userID, file, fileName) {
            return new Promise((resolve, reject) => {
                
                const filePath = `users/${userID}/profile/${fileName}`;
                const fileRef = storageRef(fbStorage, filePath);
                const resizedFilePath = `users/${userID}/profile/${fileName}_680x680`;
                const resizedFileRef = storageRef(fbStorage, resizedFilePath)
                //let uploadTask
                if (file.dataUrl) {
                    const { dataUrl = "" } = file;
                    uploadString(fileRef, dataUrl, 'data_url').then(() => {
                        keepTrying(10, resizedFileRef).then((url) => {
                            //console.log(url)
                            fbUpdateUserFiles(fileName, url)
                            resolve(url);
                        });
                    })
                } else {
                    const uploadTask = uploadBytesResumable(fileRef, file);
                    uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
/*                         switch (snapshot.state) {
                            case 'paused':
                            //console.log('Upload is paused');
                            break;
                            case 'running':
                            //console.log('Upload is running');
                            break;
                        } */
                    }, 
                    (err) => {
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        //console.log(err.code)
                        //this.error = err.code
                        reject(err);
                        /*switch (err.code) {
                        case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                        case 'storage/canceled':
                        // User canceled the upload
                        break;
                        case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    } */
                    }, 
                    () => {
                        //console.log(fileName)
                        keepTrying(10, resizedFileRef).then((url) => {
                            //console.log(url)
                            fbUpdateUserFiles(fileName, url)
                            resolve(url);
                        });
                    }
                );
                }

            });

        },
        async deleteUserFile(userID, fileName) {
            const resizePrefix = '_680x680';
            const refPath = `users/${userID}/profile/${fileName}${resizePrefix}`;
            const fileRef = storageRef(fbStorage, refPath);
            //console.log(fileRef)
            await deleteObject(fileRef).then(async() => {
                //console.log('File deleted successfully from storage')
                await fbDeleteUserFilePath(fileName)
                return true;
            }).catch((error) => {
                //console.log(error)
                this.error = error
                return false;
            });              
        },
        async uploadUserFiles(userID, files) {
            try {
                for (const fi in files) {
                    await this.uploadFile(userID, files[fi], fi)
                }
                //const lcFront = await this.uploadUserFiles(userID, file, 'lantra_card_front')
                //this.userFiles.lantraCard.front = lcFront ? lcFront : null
                //this.listUserFiles(userID)
                return true;
            } catch(e) {
                this.error = e
                return false;
            }
        } 

    },
});
  