<template>
    <ion-header>
      <ion-toolbar color="tertiary">
        <ion-title color="light">Manage Email &amp; Password</ion-title>
        <ion-buttons slot="primary">
          <ion-button @click="closeModal" fill="clear" :strong="true">
            <ion-icon color="light" :icon="closeOutline" size="large"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
  
    <ion-content>  
      <ion-grid>  
        <ion-row class="ion-padding-start ion-padding-end">

          <IonCol size="12">
            <ion-input style="margin-bottom:16px;" v-model="newUserEmail" placeholder="Email" class="ion-no-margin" type="text"></ion-input>
            <ButtonWithLoading key="emailSubmit" style="margin-bottom: 16px;"
              @click="updateEmail"
              colour="success"
              spinnerType="lines-small"
              labelText="Update Email"
              :buttonSubmit="buttonSubmitEmail"
              />
              <ButtonWithLoading key="emailPass"
              @click="updatePassword"
              colour="secondary"
              spinnerType="lines-small"
              labelText="Click Here To Update Password"
              :buttonSubmit="buttonSubmitPassword"
              />
          </IonCol>
  
          <IonCol size="12">
              <ion-button @click="doLogout" color="dark" expand="full">Sign Out</ion-button>
          </IonCol>

          <IonCol size="12" v-if="mutatedUserError != ''">
            <ion-text style="margin-bottom:16px;" color="danger">
              <p style="margin:0px;">{{ mutatedUserError }}</p>
            </ion-text>
          </IonCol>
        </ion-row>
      </ion-grid>
  
    </ion-content>
</template>
  
<script setup>
  import router from "@/router";
  import { useAuthStore } from "@/store";
  import { 
  IonContent, IonHeader, IonToolbar, IonTitle,
  IonGrid, IonInput, IonRow, toastController, IonText, onIonViewDidEnter,
  IonCol, IonButtons, IonButton, IonIcon, modalController, alertController} from "@ionic/vue";
  import { closeOutline, informationCircle } from 'ionicons/icons';
  import ButtonWithLoading from '@/components/ButtonWithLoading.vue'

  import { ref } from "vue";
  
  const { 
    userError, profile, updateUserEmailAddress, updateUserPassword, logoutUser,
  } = useAuthStore();

  onIonViewDidEnter(async () => {
    //console.log('innited ep reset')
    //await initializeProfileCollectionListener(user?.uid || '');
  });

  const closeModal = () => {
    modalController.dismiss();
  };

  const getUserEmail = profile && profile.email ? profile.email : ''
  const newUserEmail = ref(getUserEmail)
  const buttonSubmitEmail = ref(false)
  const buttonSubmitPassword = ref(false)
  const mutatedUserError = ref('')
      
  const updateEmail = async () => {
    if (newUserEmail.value == '' || newUserEmail.value == profile?.email) { return; } 
    buttonSubmitEmail.value = true

    const alert = await alertController.create({
      header: 'You are about to update your emaill address. Do you want to conitnue?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
        },
        {
          text: 'Yes',
          role: 'confirm',
        }
      ]
    });

    await alert.present()
    
    const { role } = await alert.onDidDismiss()
    
    if (role === 'confirm') {
      await updateUserEmailAddress(newUserEmail.value).then((success) => {
        if(success) {
          buttonSubmitEmail.value = false
          setTimeout( async () => {
            const toast = await toastController
              .create({
                position: 'top',
                color: 'success',
                message: 'Email Address Updated Successfully.',
                icon: informationCircle,
                duration: 1500
            })
            return toast.present();
          }, 1000)
        } else {
          mutatedUserError.value = userError
          setTimeout(() => {
            buttonSubmitEmail.value = false
        }, 500)
        }
      })
    } else {
      buttonSubmitEmail.value = false
    }
  
  }

  const updatePassword = async () => {
    buttonSubmitPassword.value = true
    await updateUserPassword().then((success) => {
      if(success) {
        //console.log(success)
        buttonSubmitPassword.value = false
        setTimeout( async () => {
          const toast = await toastController
            .create({
              position: 'top',
              color: 'success',
              message: 'Password reset email has been sent. Please follow the instruction in the email to reset your password.',
              icon: informationCircle,
              duration: 3500
          })
          return toast.present();
        }, 1000)
      } else {
        setTimeout(() => {
        buttonSubmitPassword.value = false
      }, 500)
      }
    })

  }

  const doLogout = async () => {
    await logoutUser();
    closeModal();
    router.replace("/login");
  };
 
</script>
  
  <style lang="scss" scoped>
  .fker-row {
    margin-bottom: 0;
  }
  ion-item::part(native) {
    --background: transparent;
    padding: 0;
    --inner-padding-end: 0;
    --inner-padding-start: 0;
  }
  ion-input {
    --background: #fff;
    --padding-start: 16px !important;
    --padding-bottom: 8px !important;
    --padding-top: 8px !important;
    --margin-bottom: 0 !important;
    min-height: 48px;
  }
  .dtb-inner {
    --padding-start: 0 !important;
    --background: transparent;
    --padding-bottom: 0 !important;
    --padding-top: 0 !important;
  }
  ion-button {
    height: 48px;
  }
  </style>