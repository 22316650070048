<template>
  <ion-page>
    <ion-tabs @ionTabsWillChange="beforeTabChange" @ionTabsDidChange="afterTabChange">
      <ion-header>
        <ion-toolbar color="light" mode="md" class="header-toolbar">
          <ion-title><img class="header-logo" src="@/assets/img/logo.svg" alt="logo" /></ion-title>
          <ion-buttons slot="secondary">
            <p class="user-greeting">{{ greeting() }}</p>
            <ion-button @click="openAccountModal" color="dark" size="large" shape="round" fill="clear">
              <ion-icon class="menubutton" size="large" :icon="personCircleOutline" />
            </ion-button>
            
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-router-outlet>
      </ion-router-outlet>

      <ion-tab-bar slot="bottom" color="dark">
        
        <ion-tab-button tab="staff" href="/tabs/staff">
          <ion-icon :icon="personCircleOutline" />
          <ion-label>Staff</ion-label>          
        </ion-tab-button>

        <ion-tab-button tab="chat" href="/tabs/chat">
          <ion-icon :icon="chatboxOutline" />
          <ion-label>Chat</ion-label>
          <ion-badge color="danger">{{ unseenChatsCount }}</ion-badge>       
        </ion-tab-button>

        <ion-tab-button tab="shifts" href="/tabs/shifts">
          <ion-icon :src="require(`@/assets/svg/traffic-cone.svg`)" />
          <ion-label>Shifts</ion-label>    
        </ion-tab-button>

        <ion-tab-button tab="timesheets" href="/tabs/timesheets">
          <ion-icon :icon="timeOutline" />
          <ion-label>Timesheets</ion-label>
          <ion-badge color="danger">{{ pendingTimesheetsCount }}</ion-badge>         
        </ion-tab-button>

        <ion-tab-button tab="requests" href="/tabs/requests">
          <ion-icon :icon="flashOutline" />
          <ion-label>Requests</ion-label>
          <ion-badge color="danger">{{ userRequestsCount }}</ion-badge>        
        </ion-tab-button>

        <ion-tab-button tab="employers" href="/tabs/employers">
          <ion-icon :icon="flashOutline" />
          <ion-label>Employers</ion-label>          
        </ion-tab-button>

        <ion-tab-button tab="bulletins" href="/tabs/bulletins">
          <ion-icon :icon="alertCircle" />
          <ion-label>Bulletins</ion-label>          
        </ion-tab-button>

        <ion-tab-button tab="message" href="/tabs/message">
          <ion-icon :icon="mailOpen" />
          <ion-label>SMS</ion-label>          
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { useAuthStore, useTimesheetStore } from '@/store'
import { storeToRefs } from 'pinia'
import { modalController, IonButtons, IonButton, IonIcon, IonBadge,
  IonHeader, IonToolbar, IonTitle, IonTabBar, IonTabButton,
  IonTabs, IonLabel, IonPage, IonRouterOutlet } from '@ionic/vue';
import { personCircleOutline, alertCircle, homeOutline, search, 
  timeOutline, menuOutline, calendarOutline, warning, mailOpen, flashOutline, chatboxOutline } from 'ionicons/icons';
import { defineComponent, inject } from 'vue';

import EmailPasswordModal from '@/components/account/EmailPasswordModal.vue';

export default defineComponent({
  components: { IonButtons, IonButton, IonIcon, IonBadge, IonHeader, IonToolbar, IonTitle, 
    IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage, IonRouterOutlet },
  setup() {

    const authStore = useAuthStore()

    const { unseenChatsCount, userRequestsCount } = storeToRefs(authStore)

    const timesheetStore = useTimesheetStore()

    const { pendingTimesheetsCount } = storeToRefs(timesheetStore)



    const outlet = inject("routerOutlet");

    const beforeTabChange = () => {
      // do something before tab change
      //console.log('before change')
    }
    const afterTabChange = () => {
      // do something after tab change
      //console.log('after change')
    }
    
    const openAccountModal = async () => {
      const top = (await modalController.getTop()) || outlet.value.$el;
      const modal = await modalController.create({
        component: EmailPasswordModal, //Modal is name of the component to render inside ionic modal
        canDismiss: true,
        presentingElement: top,
        //cssClass: "modal-fullscreen"
      });
      return modal.present();
    };

    const capitalLetter = (str) => {
      const arr = str.split(" ");

      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }

      const str2 = arr.join(" ");
      return str2;
    }

    const greeting = () => {
      const { userFirstName } = authStore
      const hour = new Date().getHours();
      const welcomeTypes = [`Good morning, ${userFirstName}`, `Good afternoon, ${userFirstName}`, `Good evening, ${userFirstName}`];
      let welcomeText = "";

      if (hour < 12) { welcomeText = welcomeTypes[0] }
      else if (hour < 18) { welcomeText = welcomeTypes[1] }
      else { welcomeText = welcomeTypes[2]; }

      return capitalLetter(welcomeText)
    }

    return {
      beforeTabChange,
      afterTabChange,
      openAccountModal,
      alertCircle,
      homeOutline,
      warning,
      search,
      timeOutline,
      menuOutline, 
      calendarOutline, 
      personCircleOutline,
      flashOutline,
      mailOpen,
      greeting,
      chatboxOutline,
      unseenChatsCount,
      userRequestsCount,
      pendingTimesheetsCount
    }
  }
});
</script>

<style lang="scss" scoped>
ion-toolbar {
  --min-height: 60px;
}
/* ion-toolbar {
    --background: linear-gradient(162deg, rgba(56, 70, 108, .8) 20%, rgba(56, 70, 108, .8) 100%), url('https://www.nexiga.com/geomarketing-blog/wp-content/uploads/2017/11/shutterstock_654668191_Parkhaus.jpg') bottom no-repeat;
} */
ion-tab-bar {
  border: none;
  @media only screen and (max-width: 767px) {
  overflow: auto;
  justify-content: flex-start;
  }
}
ion-tab-button[aria-selected=true] {
  background: var(--ion-color-primary-shade);
  color: var(--ion-color-primary-shade-contrast);
  &:hover {
    --color: var(--ion-color-primary-shade-contrast);
    --color-selected: var(--ion-color-primary-shade-contrast);
    background: var(--ion-color-primary-shade);
    color: var(--ion-color-primary-shade-contrast);
  }

}

.menubutton {
  --font-size:60px;
}
ion-tab-button {
  --background-focused: var(--ion-color-ptmr-grey);
  --color-focused: var(--ion-color-ptmr-grey);
  --ripple-color: var(--ion-color-ptmr-grey);
}
</style>