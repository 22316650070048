import { defineStore } from "pinia";
import { db, fbCollectionListener, queryObjectCollection, createOperation, deleteOperation, updateDocOperation } from "./firebase";
import { doc, setDoc, arrayUnion } from "firebase/firestore";
import { fbStorage, sendPushNotificationToAll } from "@/store/firebase";

import { ref as storageRef, uploadBytesResumable, getDownloadURL } from "@firebase/storage"
import { useAuthStore } from "@/store";
export const useBulletinStore = defineStore("bulletinStore", {
  // convert to a function
  state: () => ({
    bulletins: [],
    bulletin_error: null,
  }),
  getters: {
    allBulletins: (state) => {
      if(state.bulletins !== null) {
        const { usersAndCalendars } = useAuthStore();
        state.bulletins.forEach((bull) => {
          if (bull.sent_to && bull.sent_to.length) {
            let sentTo = usersAndCalendars.filter(el => bull.sent_to.indexOf(el.uid) > -1);
            bull.formatted_sent_to = sentTo
          } else {
            bull.formatted_sent_to = []
          }

          if (bull.seen_by) {
            //let seenBy = [bull.seen_by].some(obj => Object.keys(obj).includes(itm.uid));
            
            //Object.entries(bull.seen_by).map(([key]) => key);
            //console.log(seenBy)
            const wasSeenBy = usersAndCalendars.filter(itm => {
              return Object.keys(bull.seen_by).includes(itm.uid)
            })

            bull.formatted_seen_by = wasSeenBy
          } else {
            bull.formatted_seen_by = []
          }
        })
        return state.bulletins.slice()
        .sort((a, b) => b.date_created.toDate() - a.date_created.toDate());
      }
    },
    bulletinError: (state) => state.bulletin_error,
  },
  actions: {
      /**
       * listen for changes on collection and update
       * store.
       * 
       * @param collectionName 
       * @returns 
       */
    initializeCollectionListener() {
      return new Promise((resolve) => {
        fbCollectionListener('safety_bulletins', async (data) => {
          this.bulletins = data ? data : null;
          this.bulletin_error = null;
          resolve(true);
        });
      });
    },
    /**
     * make intentional call to load bulletins for collectsion
     * 
     * @param data
     */
    async loadBulletins() {
      try {
        const data = await queryObjectCollection({
          collectionName: "safety_bulletins",
        });
        this.bulletins = data ? data : null;
        this.bulletin_error = null;
        return this.bulletins;
      } catch (e) {
        this.bulletins = null;
        this.bulletin_error = e;
        return false;
      }
    },
    async createBulletin(bulletin) {
      try {
      //console.log(bulletin)
      await createOperation('safety_bulletins', bulletin)
      await sendPushNotificationToAll(
        '📌📌 You Have A New Bulletin 📌📌',
        `${bulletin.title} is now available. Please visit the app to read it.`,
        'bulletins'
      )
      return true
      } catch(e) {
        //console.log(e)
        this.bulletin_error = e
        return false;
      }
    },
    async uploadFile(file, fileName) {
      return new Promise((resolve, reject) => {
          
        const filePath = `safety_bulletins/${fileName}`;
        const fileRef = storageRef(fbStorage, filePath);

        const uploadTask = uploadBytesResumable(fileRef, file);
        uploadTask.on('state_changed', 
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          }, 
          (error) => {
            // Handle unsuccessful uploads
            reject(error);
          }, 
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log('File available at', downloadURL);
              resolve(downloadURL)
            });
          }
        );
          

      });

  },

    async updateBulletin(bull_id, bulletin) {
      try {
      //console.log(bull_id, bulletin)
      await updateDocOperation('safety_bulletins', bull_id, bulletin)
      return true
      } catch(e) {
        //console.log(e)
        this.bulletin_error = e
        return false;
      }
    },
    async deleteBulletin(bulletin_id) {
      try {
      //console.log(bulletin_id)
      await deleteOperation('safety_bulletins', bulletin_id)
      return true
      } catch(e) {
        //console.log(e)
        this.bulletin_error = e
        return false;
      }
    },
    async addSentBulletinTo(user_id, bull_id) {
      try {
          console.log('sending bulletin id ', bull_id);
          const bullRef = doc(db, "safety_bulletins", bull_id);
          await setDoc(bullRef, {
            sent_to: arrayUnion(user_id)
          }, { merge: true }).then(() => {
            this.error = null;
          }).catch(err => this.error = err)
          return true;
      } catch (e) {
          this.error = e;
      }
    },
  },
});