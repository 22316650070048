/* eslint-disable no-unused-vars */
import { 
  createUserWithEmailAndPassword,
  getAuth 
} from "firebase/auth";
import { defineStore, getActivePinia, storeToRefs } from "pinia";

import {
  fbAuthStateListener,
  fbUpdateAccount,
  fbUpdateUserEmail,
  fbUpdateUserPassword,
  fbSignIn,
  fbSignOut,
  fbUpdateEmployee,
  fbCollectionListener,
  fbCollectionListenerByDocID,
  createOperationByCollectionID,
  createOperation,
  sendMessageOperation,
  sendGeneralMessageOperation,
  deleteOperation,
  fbChatsCollection,
  auth,
} from "./firebase";

import { 
  useShiftStore,
  useTimesheetStore,
  useBulletinStore,
  useEmployerStore,
  //useUploadStore
} from "@/store";


export const useAuthStore = defineStore("authStore", {
  // convert to a function
  state: () => ({
    user: null,
    user_calendars: null,
    userShifts: null,
    employees: null,
    profile: null,
    users: null,
    calendar: null,
    error: null,
    staffRequests: null,
    chats: null
  }),
  getters: {
    userCalendar: (state) => state.calendar,
    formattedCalendar() { return this.userCalendar && this.userCalendar.length ? this.userCalendar.map(cal => cal.toDate().toISOString().split('T')[0]) : null},
    userCalendarCount() { return this.userCalendar && this.userCalendar.length ? this.userCalendar.length : 0},
    userFullName() {
      if (this.profile && this.profile.first_name != '' & this.profile.surname != '') {
        return `${this.profile.first_name} ${this.profile.surname}`
      }
    },
    userFirstName() {
      if (this.profile && this.profile.first_name != '' & this.profile.surname != '') {
        return `${this.profile.first_name}`
      }
    },
    usersAndCalendars() {
      if (this.users && this.users.length && this.user_calendars && this.user_calendars.length) {
        const sortByDate = (data) => data.sort((a, b) => a < b ? -1 : a > b ? 1 : 0)
        this.user_calendars.forEach((cal) => {
          sortByDate( (cal.dates))
          this.removeTimestampsBeforeToday(cal)
        })

        this.users.sort((a, b) => a.first_name.localeCompare(b.first_name))

       
        const x = this.mergeArrays(this.users, this.user_calendars)

        const objWithIdIndex = x.findIndex((obj) => obj.id === 'IG4mJQzbLoUyOAyWNqJL4WGxRVk1');

        if (objWithIdIndex > -1) {
          x.splice(objWithIdIndex, 1);
        } 


        return x
      } else {
        return []
      }
    },
    isLoggedIn: (state) => state.user !== null,
    isAdmin: (state) => state.profile !== null && state.profile.isAdmin === true,
    userError: (state) => state.error,
    ascUsers() {
      if (this.users && this.users.length) {
        
        const result = this.users.sort((a, b) => {
          const aFirstName = a.first_name || a.surname;
          const bFirstName = b.first_name || b.surname;
          
          // Sort in ascending order based on first_name
          return aFirstName.localeCompare(bFirstName);
        });

        return result
      } else {
        return []
      }
    },
    usersAndRequests() {
      if (this.users && this.users.length && this.staffRequests && this.staffRequests.length) {
        
        const result = this.staffRequests.map((obj) => ({
          ...obj,
          ...this.users.find((o) => o.uid === obj.uid),
          req_id: obj.id
        }));

        return result
      } else {
        return []
      }
    },
    userRequestsCount: (state) => {
      if (state.staffRequests && state.staffRequests.length) {
        return state.staffRequests.length;
      }
      return 0; // Return 0 if there are no chats or the chats array is empty
    },
    userLatestShift() {
      if (this.users && this.users.length && this.userShifts && this.userShifts.length) {
        const now = new Date(); // Get the current date and time
    
        const result = this.userShifts.map((obj) => {
          const user = this.users.find((o) => o.uid === obj.userId);
    
          // Extract shift dates from different arrays
          const allShiftIds = [
            ...obj.requestedShifts,
            ...obj.visibleShifts,
            ...obj.acceptedShifts,
            ...obj.declinedShifts
          ];
    
          const shifts = useShiftStore();
    
          // Find the latest shift among all the shift dates
          let latestShift = null;
          let status = null;
          let employer = null;
    
          allShiftIds.forEach(shiftId => {
            const shift = shifts.shifts.find(s => s.id === shiftId);
            if (shift && (!latestShift || shift.start_date.toDate() > latestShift.start_date.toDate())) {
              latestShift = shift;
              employer = shift.employer;
              if (obj.requestedShifts.includes(shiftId)) {
                status = 'requested';
                latestShift.status = 'requested';
              } else if (obj.visibleShifts.includes(shiftId)) {
                status = 'visible';
                latestShift.status = 'visible';
              } else if (obj.acceptedShifts.includes(shiftId)) {
                status = 'accepted';
                latestShift.status = 'accepted';
              } else if (obj.declinedShifts.includes(shiftId)) {
                status = 'declined';
                latestShift.status = 'declined';
              }
            }
          });
    
          if (latestShift) { // Only include users with at least one shift
            return {
              ...obj,
              ...user,
              latestShift,
              status,
              employer
            };
          }
        });
    
        // Filter out undefined values (users without shifts)
        return result.filter(user => user !== undefined);
      } else {
        return [];
      }
    },
    usersAndShifts() {
      if (this.users && this.users.length && this.userShifts && this.userShifts.length) {
        const result = this.userShifts.map((obj) => {
          const user = this.users.find((o) => o.uid === obj.userId);
          return {
            ...obj,
            ...user,
          };
        }).sort((a, b) => {
          const aTotal =  a.requestedShifts.length + a.visibleShifts.length + a.acceptedShifts.length + a.declinedShifts.length;
          const bTotal = b.requestedShifts.length + b.visibleShifts.length + b.acceptedShifts.length + b.declinedShifts.length;
      
          // Sort in descending order based on the total of shifts
          return bTotal - aTotal;
        });
    
        return result;
      } else {
        return [];
      }
    },
    allChatsOrdered: (state) => {
      if (state.chats && state.chats.length) {
        const unseenChats = state.chats.filter((chat) => chat.seen === false);
        const seenChats = state.chats.filter((chat) => chat.seen === true);
    
        return [...unseenChats, ...seenChats];
      }
    
      return [];
    },    
    unseenChatsCount: (state) => {
      if (state.chats && state.chats.length) {
        const unseenChatsCount = state.chats.filter((chat) => chat.seen === false);
        return unseenChatsCount.length;
      }
      return 0; // Return 0 if there are no chats or the chats array is empty
    }
  },
  actions: {
    initializeCalendarCollectionListener(docID) {
      return new Promise((resolve) => {
        fbCollectionListenerByDocID('calendars', docID, async (data) => {
          this.calendar = data ? data.dates : null;
          this.error = null;
          resolve(true);
        });
      });
    },
    initializeProfileCollectionListener(docID) {
      return new Promise((resolve) => {
        fbCollectionListenerByDocID('users', docID, async (data) => {
          this.profile = data ? data : null;
          this.error = null;
          resolve(true);
        });
      });
    },
    initializeUsersCollectionListener() {
      return new Promise((resolve) => {
        fbCollectionListener('users', async (data) => {
          this.users = data ? data : null;
          this.error = null;
          resolve(true);
        });
      });
    },
    initializeUserCalendarsCollectionListener() {
      return new Promise((resolve) => {
        fbCollectionListener('calendars', async (data) => {
          this.user_calendars = data ? data : null;
          this.error = null;
          resolve(true);
        });
      });
    },
    initializeUserRequestsCollectionListener() {
      return new Promise((resolve) => {
        fbCollectionListener('user_requests', async (data) => {
          this.staffRequests = data ? data : null;
          this.error = null;
          resolve(true);
        });
      });
    },
    initializeUserShiftsCollectionListener() {
      return new Promise((resolve) => {
        fbCollectionListener('user_shifts', async (data) => {
          this.userShifts = data ? data : null;
          this.error = null;
          resolve(true);
        });
      });
    },
    initializeChatsCollectionListener() {
      return new Promise((resolve) => {
        fbChatsCollection(async (data) => {
          this.chats = data;
          this.error = null;
          resolve(true);
        });
      });
    },  
    mergeArrays(arr1 = [], arr2 = []) {
      let res = [];
      res = arr1.map(obj => {
         const index = arr2.findIndex(el => el["id"] == obj["id"]);
         const { dates } = index !== -1 ? arr2[index] : {};
         return {
            ...obj,
            dates
         };
      });
      return res;
    },
    removeTimestampsBeforeToday(obj) {
      const today = new Date();
      obj.dates = obj.dates.filter(timestamp => {
        const date = new Date((timestamp.seconds + timestamp.nanoseconds / 1e9) * 1000);
        return date >= today;
      });
      return obj;
    },
    initializeAuthListener() {
      return new Promise((resolve) => {
        fbAuthStateListener(async (user) => {
          const shifts = useShiftStore()
          const sheets = useTimesheetStore();
          const bulletins = useBulletinStore();
          const employers = useEmployerStore()
          this.user = user ? user : null;
          if (user) {
            await this.initializeProfileCollectionListener(user.uid)
            await this.initializeUsersCollectionListener()
            await this.initializeUserCalendarsCollectionListener()
            await this.initializeChatsCollectionListener()
            await employers.initializeCollectionListener()
            await shifts.getAllShifts()
            //await shifts.getAllUserShifts()
            await sheets.initializeCollectionListener()
            await bulletins.initializeCollectionListener()
            await this.initializeUserRequestsCollectionListener()
            await this.initializeUserShiftsCollectionListener()
          }
          resolve(true)
        });
      });
    },
    async deleteTrainingRequest(req_id) {
      try {
          ////console.log('Cancelling a shift request with id of ', shift_id);
          await deleteOperation('user_requests', req_id);
          this.error = null;
          return true;
      } catch (e) {
          //console.log(e);
          this.error = e;
      }
    },
    /**
     *
     * @param login user
     */
    async logInUser(email, password) {
      try {
        const response = await fbSignIn(email, password);
        this.user = response.user;
        this.error = null;
        return true;
      } catch (e) {
        //console.log(e)
        this.user = null;
        this.profile = null;
        this.calendar = null;
        this.error = e.code;
        return false;
      }
    },
    /**
     *
     * @param logout user
     */
    async logoutUser() {
      const shiftStore = useShiftStore()
      const sheetStore = useTimesheetStore();
      const bulletinStore = useBulletinStore();

      const { shifts, userShifts, currentShift, currentShiftID } = storeToRefs(shiftStore)
      const { bulletins } = storeToRefs(bulletinStore)
      const { 
        newTimesheetDates, newSingleDatesFormatted, new_timesheet, 
        timesheets, accepted_timesheets, rejected_timesheets, 
        pending_timesheets 
      } = storeToRefs(sheetStore)
      try {
        await fbSignOut().then(() => {
          this.user = null;
          this.user_calendars = null;
          this.employees = null;
          this.profile = null;
          this.users = null;
          this.calendar = null;
          this.error = null;
          this.staffRequests = null;
          this.chats = null;
          shifts.value = null;
          userShifts.value = null;
          currentShift.value = null;
          currentShiftID.value = null;
          bulletins.value = null;
          newTimesheetDates.value = [];
          newSingleDatesFormatted.value = [];
          new_timesheet.value = {};
          timesheets.value = [];
          accepted_timesheets.value = [];
          rejected_timesheets.value = [];
          pending_timesheets.value = [];
          // get all the states and store them in a list
          const states = Object.keys(getActivePinia()?.state.value);

          // map through that list and use the **$reset** fn to reset the state
          states.map((state) => getActivePinia()._s.get(state).$reset());
        })

        return true;
      } catch (e) {
        this.error = e;
        return false;
      }
    },
    /**
     *
     * @param create account
     */
     async createAccount(
      email, 
      password,
    ) {
      await createUserWithEmailAndPassword(auth, email, password).then((response) => {        
        this.user = response.user
      })
      .catch((e) => {
        this.error = e.code;
      }).finally(async () => {
        //console.log('all done')
      })
      },
    /**
     *
     * @param update account
     */
    async userUpdateAccount(
      first_name,
      surname,
      date_of_birth,
      cscs_no,
      national_insurance_no,
      qualification,
      address_line_1,
      address_line_2,
      city,
      postcode,
      tel,
    ) {
      try {
        const { profile } = await fbUpdateAccount(
          first_name,
          surname,
          date_of_birth,
          cscs_no,
          national_insurance_no,
          qualification,
          address_line_1,
          address_line_2,
          city,
          postcode,
          tel   
        )
        this.profile = profile ? profile : null;
        return true;
      } catch (e) {
          this.profile = null;
          this.error = e;
          return false;
      }
    },
    /**
     *
     * @param UPDATE user password by sending password reset email
     */
     async updateUserPassword(
      email
    ) {
      let user_email
      const auth = getAuth();
      if (email && email != '') {
        user_email = email
      } else {
        user_email = auth.currentUser.email
      }
      
        try {
          await fbUpdateUserPassword(auth, user_email);
          this.error = null;
          return true;
        } catch (e) {
          this.error = e.code;
          return false;
        }
      },
    /**
     *
     * @param UPDATE user email address
     */
     async updateUserEmailAddress(
      newEmail
      ) {
        const auth = getAuth();
          try {
            //const { profile } = 
            await fbUpdateUserEmail(auth.currentUser, newEmail);
            //this.profile = profile ? profile : null;
            this.error = null;
            return true;
          } catch (e) {
            this.error = e.code;
            return false;
          }
        },
    /**
     *
     * @param save user calendar dates
     */
    async saveUserDates(id, dates) {

        try {
            const newD = dates?.map((d) => { return new Date(d) })
            const dataObj = {
                dates: newD
            }

            await createOperationByCollectionID('calendars', id, dataObj);
            this.error = null;
            return true;
        } catch (e) {
            //this.new_date = null;
            this.error = e;
        }
    },
    /**
     *
     * @param save send user request
     */
    async sendUserRequest(data) {
      try {
        await createOperation('user_requests', data);
        this.error = null;
        return true;
    } catch (e) {
        this.error = e.code;
    }
    },
    async updateEmployeeAccount(
      uid,
      first_name,
      surname,
      date_of_birth,
      cscs_no,
      national_insurance_no,
      qualification,
      address_line_1,
      address_line_2,
      city,
      postcode,
      tel,
      emergency_contact_name,
      emergency_contact_tel
    ) {
      try {
        await fbUpdateEmployee(
          uid,
          first_name,
          surname,
          date_of_birth,
          cscs_no,
          national_insurance_no,
          qualification,
          address_line_1,
          address_line_2,
          city,
          postcode,
          tel,
          emergency_contact_name,
          emergency_contact_tel
        )
        //this.profile = profile ? profile : null;
        return true;
      } catch (e) {
          //this.profile = null;
          this.error = e;
          return false;
      }
    },
    async sendMessage(data, msgType) {
      try {
        if (!data || !data.to) return;
        console.log('from user js. msgType = ', msgType)
        console.log(data)
         if (msgType == 'general') {
          await sendGeneralMessageOperation(data);
        } else {
          await sendMessageOperation(data);
        }
        this.error = null;
        return true;
    } catch (e) {
        this.error = e.code;
    }
    },
    async deleteEmployeeAccount(uid) {
      try {
        await deleteOperation('users', uid);
        return true;
      } catch (e) {
          this.error = e;
          return false;
      }
    },
  },
});
