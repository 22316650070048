import { defineStore } from "pinia";
import { fbCollectionListener, createOperation, deleteOperation } from "./firebase";

export const useEmployerStore = defineStore("EmployerStore", {
  // convert to a function
  state: () => ({
    employers: [],
    employer_error: null,
  }),
  getters: {
    allEmployers: (state) => {
      return state.employers.sort((a, b) => {
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });
    },
    employerError: (state) => state.employer_error,
  },
  actions: {
    initializeCollectionListener() {
      return new Promise((resolve) => {
        fbCollectionListener('employers', async (data) => {
          this.employers = data ? data : null;
          this.employer_error = null;
          resolve(true);
        });
      });
    },
    async createEmployer(employer) {
        try {
            await createOperation('employers', employer)
            return true
        } catch(e) {
          this.employer_error = e
          return false;
        }
    },
    async deleteEmployer(employer_id) {
        try {
            await deleteOperation('employers', employer_id)
            return true
        } catch(e) {
          this.employer_error = e
          return false;
        }
    },
  },
});